html,
body,
#root,
.root-container {
  width: 100%;
  height: 100%;
}
.root-container {
  display: flex;
}
.sidebar {
  width: 400px;
  height: 100%;
}
.main {
  flex-grow: 1;
  height: 100%;
}

.sidebar-top {
  display: flex;
  padding: 10px;
}
.square-logo {
  width: 60px;
  height: 60px;
  margin: 5px;
}
.app-name {
  font-size: 1.6em;
  font-weight: 600;
}
.sidenav-menu {
  width: 100%;
}

.app-page-title {
  padding: 10px;
}

.loading-animaiton-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.loading-animation {
  width: 20em;
}
.page_container {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  justify-content: center;
}
.profile_container {
  width: 60em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile_photo {
  background-color: gray;
  width: 10em;
  height: 10em;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.profile_name {
  font-size: 25pt;
  font-weight: bold;
}
